import * as React from "react";
import { Helmet } from "react-helmet";

import Header from "../components/Header";

// markup
const NotFoundPage = () => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" name="House 5" description="House 5" />
        <title>House 5 | Page Not Found</title>
      </Helmet>
      <Header />
      <h1>PAGE NOT FOUND</h1>
    </main>
  );
};

export default NotFoundPage;
